<template lang="html">
    <section class="automatically-box ">
        <div class="container grid-2 demo ">

            <div class="content demo2 grid">
                <img src="/img/confirmacion.svg" alt="confirmacion">


            </div>


            <div class="content demo grid">
                <p class="title-big -blueDarkC demo">
                    {{ $t('newsletter.thanks.intro') }}
                </p>
                <br><br>

                <p class=" -blueDarkC text">
                    {{ $t('newsletter.thanks.text1') }}
                </p>
                <br>

                <br>


            </div>
        </div>
    </section>

</template>

<script lang="js">


</script>
  
  
  <style scoped lang="scss">
    .automatically-box {
    background: white;
    }
    *{
        color:#373F41;
    }
  a {
      color: white
  }
  
  .container {
      margin-top: 50px
  }
  
  .title-big {
      font-weight: 700;
      font-size: 35px;
      line-height: 44px;
      letter-spacing: 0.2px;
  }
  
  .text {
      font-weight: 700;
      font-size: 26px;
      line-height: 183.85%;
      font-style: normal
  }
  
  @media screen and (max-width:900px) {
      img {
          width: 300px;
      }
  
      .title-big {
          font-weight: 700;
          font-size: 25px;
          line-height: 44px;
          letter-spacing: 0.2px;
      }
  
      .text {
          font-weight: 700;
          font-size: 18px;
          line-height: 183.85%;
          font-style: normal;
      }
  
      .content.demo2.grid {
          display: flex;
          align-items: center;
          justify-content: center;
      }
  
      .grid-2 {
          display: flex;
          flex-wrap: wrap;
          padding: 40px 0;
          justify-content: center;
          flex-direction: column-reverse;
          align-content: center;
          align-items: center;
      }
  
      .container .content.demo {
          width: 100%;
          /* padding-left: 120px; */
          padding: 20px;
      }
  }
  </style>